import React, { useEffect, useState } from 'react';
import { useParams } from '@reach/router';

import MainLayout from '../../layouts/mainlayout/MainLayout';
import PageHeader from '../../components/pageheader/PageHeader';
import { BASE_URL } from "../../utils/constants";

export default function AnnouncementTemplate() {
	const { slug } = useParams();
	const [announcement, setAnnouncement] = useState({});

	useEffect(() => {
		console.log(slug);
		fetch(`${BASE_URL}/api/open/announcements/${slug}`)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setAnnouncement(data.Data);
			})
	}, [slug])

	return (
		<MainLayout title={announcement.title}>
			<PageHeader title="Announcements" subnav='announcements' subtitle={announcement.title} />
			<div className='announcement-layout'>
				<h1 className="announcement-head mt-3">{announcement.title}</h1>
				<div className="date">{announcement.date ? new Date(announcement.date).toDateString() : "" }</div>
				<div className="my-5 row">
					<div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 px-5 px-md-3">
						<img src={announcement.featuredImg} className='w-100' alt="" />
					</div>
					<div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 px-5 pt-5 pt-md-0">
						<div className="body" dangerouslySetInnerHTML={{ __html: announcement.html }} />
					</div>
				</div>
			</div>
		</MainLayout>
	);
}
