import React from "react"
import { Router } from "@reach/router"
import AnnouncementTemplate from "../../components/announcements/announcement_template"

const app = () => {
	return (
		<Router basepath="/announcement">
			<AnnouncementTemplate path="/:slug" />
		</Router>
	)
}

export default app